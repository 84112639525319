<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <div class="card">
                <div class="card-header bg-white">
                    <h6 class="card-title font-weight-semibold">Tambah Catatan Asuhan</h6>
                </div>
                <validation-observer ref="VForm">
                <b-form @submit.prevent="doSubmit">
                    <div class="table-responsive">
                    <table class="table table-bordered table-sm table-align-top table-striped table-hover">
                        <thead>
                        <tr>
                            <td colspan="6" class="bg-info">
                            <span class="font-weight-semibold">Catatan Asuhan Perawat</span>
                            </td>
                        </tr>
                        <tr>
                            <th width="40%">Asesmen/Tindakan</th>
                            <th width="40%">Evaluasi</th>
                            <th>PPA</th>
                            <th>Action</th>
                            <th>Hapus</th>
                        </tr>
                        </thead>
                        <tbody>
                        
                        <tr v-for="(v,k) in data" :key="k">
                            <td>
                            <div class="form-row">
                                <div class="col-12" v-if="data[k].aucap_created_from == 'CA'">
                                    <div class="mb-2 mt-1">
                                        <b-form-radio-group
                                            :options="Config.mr.ugdTindakanLanjut"
                                            v-model="data[k]['aucap_type']"
                                        />

                                        <VValidate :name="'Tindak lanjut #'+(k+1)" v-model="data[k]['aucap_type']"
                                        :rules="mrValidation.aucap_type" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label>Tanggal</label>
                                        <div class="input-group">
                                            <datepicker input-class="form-control transparent"
                                            placeholder="Pilih Tanggal" class="my-datepicker"
                                            calendar-class="my-datepicker_calendar" v-model="data[k]['aucap_date']">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                        <VValidate :name="'Tanggal #'+(k+1)" v-model="data[k]['aucap_date']"
                                        :rules="mrValidation.aucap_date" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label>Jam</label>
                                        <vue-timepicker v-model="data[k]['aucap_jam']" format="HH:mm" input-class="form-control"></vue-timepicker>
                                        <VValidate :name="'Jam #'+(k+1)" v-model="data[k]['aucap_jam']"
                                        :rules="mrValidation.aucap_jam" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="my-2" v-if="data[k]['aucap_type'] == 'L'">
                                <label for="">Asesmen/Tindakan</label>
                                <v-select 
                                    @input="changeAsesmenTindakan(k)"
                                    :options="Config.mr.ugdAssesmenTindakan"
                                    v-model="data[k]['aucap_assesmen_tindakan']"
                                    placeholder="Pilih Asesmen/Tindakan"
                                    label="text"
                                    :clearable="true" :reduce="v=>v.value"
                                >
                                </v-select>
                                <VValidate :name="'Asesmen/Tindakan #'+(k+1)" v-model="data[k]['aucap_assesmen_tindakan']"
                                    :rules="mrValidation.aucap_assesmen_tindakan" />
                            </div>

                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                                <div class="form-inline">
                                <label>Incici pada</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm mx-1">
                                </div>
                            </div>

                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                                <div class="form-inline">
                                <label>Warna gelang</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm mx-1">
                                </div>
                            </div>
                            
                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang bidai/spalk'">
                                <div class="form-inline">
                                <label>Evaluasi Pada</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm mx-1">
                                </div>
                            </div>

                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pengambilan benda asing/corpal'">
                                <div class="form-inline">
                                <label>Evaluasi pada</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm mx-1">
                                </div>
                            </div>
                            
                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                                <div class="mb-2">
                                <label>Nama Obat</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm">
                                </div>
                            </div>
                            
                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                                <div class="mb-2">
                                <label>Nama Obat</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm">
                                </div>
                                
                                <div class="mb-2">
                                <label>Dosis</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text2']" class="form-control form-control-sm">
                                </div>
                            </div>

                            <div class="my-2" v-if="data[k]['aucap_type'] !== 'L' || (data[k]['aucap_assesmen_tindakan'] && data[k]['aucap_created_from'] != 'CA')">
                                <label for="">Asesmen/Tindakan</label>
                                <b-form-textarea v-model="data[k]['aucap_assesmen_tindakan_text']" name="" id="" rows="3" class="form-control"></b-form-textarea>
                                <VValidate :name="'Asesmen/Tindakan Lainnya #'+(k+1)" v-model="data[k]['aucap_assesmen_tindakan_text']"
                                    :rules="mrValidation.aucap_assesmen_tindakan_text" />
                            </div>

                            <div class="assessContent"></div>
                            </td>
                            <td>
                            <div class="form-row">
                                <div class="col-md-6">
                                    <div>
                                        <label>Tanggal</label>
                                        <div class="input-group">
                                            <datepicker input-class="form-control transparent"
                                            placeholder="Pilih Tanggal" class="my-datepicker"
                                            @input="inputDataCA(data[k]['aucap_evaluasi_date'],data[k]['aucap_evaluasi_jam'],k)"
                                            calendar-class="my-datepicker_calendar" v-model="data[k]['aucap_evaluasi_date']">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                        <VValidate :name="'Tanggal Evaluasi #'+(k+1)" v-model="data[k]['aucap_evaluasi_date']"
                                        :rules="mrValidation.aucap_evaluasi_date" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label>Jam</label>
                                        <vue-timepicker @input="inputDataCA(data[k]['aucap_evaluasi_date'],data[k]['aucap_evaluasi_jam'],k)"
                                        v-model="data[k]['aucap_evaluasi_jam']" format="HH:mm" input-class="form-control"></vue-timepicker>
                                        <VValidate :name="'Jam Evaluasi #'+(k+1)" v-model="data[k]['aucap_evaluasi_jam']"
                                        :rules="mrValidation.aucap_evaluasi_jam" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-2" v-if="data[k]['aucap_type'] !== 'L' || data[k]['aucap_assesmen_tindakan'] == 'Free Text'">
                                <label for="">Evaluasi</label>
                                <div class="mb-2 evalContent">
                                <b-form-textarea v-model="data[k]['aucap_evaluasi_text']" id="" rows="2" class="form-control"></b-form-textarea>
                                <VValidate :name="'Evaluasi Lainnya #'+(k+1)" v-model="data[k]['aucap_evaluasi_text']"
                                :rules="mrValidation.aucap_evaluasi_text" />
                                </div>
                            </div>

                            <template v-else>
                                <div class="mt-2" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan oksigen'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Oksigen terpasang</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>L/menit, SpO2</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan Perekaman EKG'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Hasil Terlampir</span>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang bedside monitor'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Monitor terpasang</span>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemeriksaan cek gula darah acak'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Hasil GDA</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-2">
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan infus sesuai dengan advis dokter'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                            <label>Infus terpasang cairan</label>
                                            <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                            <label>dengan tetesan</label>
                                            <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                            <label>/menit tusukan pada</label>
                                            <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                            <label>dengan abocath</label>
                                            <input v-model="data[k]['aucap_evaluasi_t4']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Injeksi masuk lewat</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>tanda-tanda alergi</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>keluhan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan kateter tetap'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Kateter terpasang nomor</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>dengan balon WFI</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>urin keluar</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        <label>CC setelah pemasangan</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan suction'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Lendir terhisap, suara nafas</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan penjahitan luka'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Luka terjahit</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>. Simpul dengan jenis dan ukuran benang</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                   
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Kondisi luka setelah dilakukan perawatan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan ekstraksi kuku'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Kuku bisa terangkat, luka bersih</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>sudah terambil perawatan luka telah dilakukan, luka bekas incici bersih</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Terpasang gelang identitas warna </label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>Pasien maupun keluarga paham penjelasan petugas</label>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang penanda alergi'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Terpasang gelang alergi warna merah dengan tulisan alergen </label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang bidai/spalk'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Terpasang bidai pada</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>Hasil pengecekan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>tanda tanda kompartemen</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pengambilan benda asing/corpal'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Benda asing pada</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>pada area</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan irigasi mata'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <b-form-textarea v-model="data[k]['aucap_evaluasi_t1']" class="form-control"></b-form-textarea>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan lavement'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Lavement masuk</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>CC dengan cairan yang digunakan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>hasil evaluasi feses</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pelepasan kateter tetap'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Kateter berhasil dilakukan pelepasan dengan bleder training sebelumnya</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Nebulizer telah dilakukan, sesak nafas</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']"  class="form-control form-control-sm mx-1">
                                        <label>Spo2</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']"  class="form-control form-control-sm mx-1">
                                        <label>sputum</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']"  class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>


                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang NGT'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>NGT terpasang dengan ukuran</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>pada hidung sebelah</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>hasil evaluasi terdengar bunyi blup pada area lambung</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka combutio'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Luka tampak</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>Bula</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>perawatan menggunakan topical terapi</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Obat masuk melalui syring pump, lancar</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan CTG'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Hasil terlampir</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memfasilitasi pasien untuk pemeriksaan radiologi'">
                                    <div class="mb-2 evalContent">
                                        <span>Hasil terlampir</span>
                                    </div>
                                </div>




                                
                                
                            </template>
                            </td>
                            <td>
                                <div class="" style="width:100px">
                                <v-select 
                                    :options="Config.mr.isPerawatOrDokter"
                                    v-model="data[k]['aucap_ppa']"
                                    placeholder="Pilih PPA"
                                    label="text"
                                    :clearable="true" :reduce="v=>v.value"
                                >
                                </v-select>
                                </div>
                                <template>
                                    <b-form-input class="mt-2" v-model="data[k]['aucap_ppa_lainnya']" placeholder="Tulis Nama" />
                                </template>
                                <VValidate :name="'PPA #'+(k+1)" v-model="data[k]['aucap_ppa']"
                                :rules="mrValidation.aucap_ppa" />

                                
                            </td>
                            <td>
                            <div class="mb-2">
                                <b-form-checkbox @input="changeDoneInput($event,k)" :unchecked-value="'N'" :value="'Y'"
                                v-model="data[k]['aucap_is_done']" class="form-check-input-styled" name="radio-inline-left">
                                </b-form-checkbox>
                                <span v-if="data[k]['aucap_is_done'] == 'Y'">Selesai pada {{ v.aucap_evaluasi_date | moment("DD MMMM YYYY") }} {{v.aucap_evaluasi_jam}}</span>
                            </div>
                            </td>
                            <td>
                                <a href="javascript:;" class="list-icons-item"
                                   @click="removeData(v,k)"
                                    data-toggle="tooltip" data-placement="top" title="Delete"><i
                                class="icon-bin"></i></a>
                            </td>
                        </tr>

                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="6" class="text-center">
                            <a href="javascript:;" @click="addCatatan()" class="btn btn-outline-info"><i class="icon-plus2 mr-2 align-middle"></i><span class="align-middle">Tambah Catatan</span></a>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>
                    <div class="table-responsive">
                    <table class="table table-bordered table-sm table-align-top">
                        <thead>
                        <tr>
                            <td colspan="6" class="bg-indigo">
                            <span class="font-weight-semibold">Catatan Asuhan Dokter</span>
                            </td>
                        </tr>
                        <tr>
                            <th>Waktu</th>
                            <th>Asesmen/Tindakan</th>
                            <th>Waktu Evaluasi</th>
                            <th>Evaluasi</th>
                            <th>PPA</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in (dataKajianDokter||[])" :key="k">
                            <td>
                            <span>{{v.aucad_tanggal | moment("DD MMMM YYYY")}}, {{v.aucad_jam}}</span>
                            </td>
                            <td>
                            <span>{{v.aucad_assesmen||"-"}}</span>
                            </td>
                            <td>
                            <span>{{v.aucad_tanggal_evaluasi | moment("DD MMMM YYYY")}}, {{v.aucad_jam_evaluasi||"-"}}</span>
                            </td>
                            <td>                            
                            <span>{{v.aucad_evaluasi||"-"}}</span>
                            </td>
                            <td>
                            <span>{{v.aucad_ppa||"-"}}</span>
                            </td>
                            <td>
                            <span v-if="v.aucad_is_active != 'Y'"> - </span>
                            <span v-else>Selesai pada {{v.aucad_done_at | moment("DD MMMM YYYY, HH:mm")}}</span>
                            </td>
                        </tr>
                        <tr v-if="!(dataKajianDokter||[]).length">
                            <td colspan="6" class="text-center">Tidak ada data</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>

                    <div class="table-responsive">
                        <table v-if="showIntervensi || (row.ap_usia == null && row.ap_dob == null && (edTotal >= 90 || hdTotal >= 12 || morsheTotal >= 25 || getTotalSydney >=6))" class="table table-bordered table-sm table-striped">
                        <thead>
                            <tr>
                                <td colspan="2" class="bg-indigo">
                                <span class="font-weight-semibold">Intervensi Risiko Jatuh</span>
                                </td>
                            </tr>
                            <tr>
                                <th width="50%">Tindakan</th>
                                <th width="50%">Evaluasi</th>
                            </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_gelang == 'Y'">
                                        <td>
                                            Memasang gelang kuning risiko jatuh
                                        </td>
                                        <td>
                                            <div class="form-inline">
                                                <label>Terpasang gelang warna kuning pada</label>
                                                <b-form-input v-model="dataKajianPerawat.aukprj_memasang_gelang_text" type="text" class="form-control form-control-sm mx-2" />
                                                <label>pasien</label>
                                            </div>

                                            <VValidate 
                                                name="Memasang Gelang Kuning" 
                                                v-if="dataKajianPerawat.aukprj_memasang_gelang == 'Y'"
                                                v-model="dataKajianPerawat.aukprj_memasang_gelang_text" 
                                                :rules="{required: 1}"
                                            />
                                        </td>
                                    </tr>
                                    <tr  v-if="dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">
                                        <td>
                                            Memasang pagar pengaman tempat tidur
                                        </td>
                                        <td>
                                            <span>Pagar pengaman tempat tidur terpasang pada kedua sisi</span>
                                        </td>
                                    </tr>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_roda == 'Y'">
                                        <td>
                                            Mengunci roda tempat tidur
                                        </td>
                                        <td>
                                            <span>Roda tempat tidur terkunci dan tidak mudah tergeser</span>
                                        </td>
                                    </tr>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_restrain == 'Y'">
                                        <td>
                                        Memasang restrain
                                        </td>
                                        <td>
                                            <div class="form-inline">
                                                <label>Terpasang restrain di</label>
                                                <b-form-input v-model="dataKajianPerawat.aukprj_memasang_restrain_text" type="text" class="form-control form-control-sm mx-2" />
                                                <label>pasien, restrain menggunakan kain</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">
                                        <td>
                                            Memberikan edukasi pencegahan jatuh pada pasien dan keluarga
                                        </td>
                                        <td>
                                            <span>Edukasi tentang pasien dipastikan selalu ada yang menunggu</span>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>

                    <div class="card-footer">
                    <div class="text-right">
                        <a href="javascript:;" @click="back()" class="btn">Kembali</a>
                        <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                    </div>
                    </div>
                </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
    extends: GlobalVue,
    data() {
        return {
            rowReg: {},
            data: [],
            dataKajianDokter: [],
            dataKajianPerawat: {}
        }
    },
    computed:{
        now() {
            return moment()
        },

        edTotal(){
            return (this.dataKajianPerawat.aukprj_ed_umur||0) + 
            (this.dataKajianPerawat.aukprj_ed_status_mental||0) + 
            ((this.dataKajianPerawat.aukprj_ed_pola_pikir == 11 ? (11+1): this.dataKajianPerawat.aukprj_ed_pola_pikir)||0) + 
            (this.dataKajianPerawat.aukprj_ed_pengobatan||0) + 
            (this.dataKajianPerawat.aukprj_ed_diagnosis||0) + 
            (this.dataKajianPerawat.aukprj_ed_ambulasi||0) + 
            (this.dataKajianPerawat.aukprj_ed_nutrisi||0) + 
            (this.dataKajianPerawat.aukprj_ed_riwayat_jatuh||0) 
        },

        hdTotal(){
            return (this.dataKajianPerawat.aukprj_humpty_umur||0) + 
            (this.dataKajianPerawat.aukprj_humpty_jenis_kelamin||0) + 
            (this.dataKajianPerawat.aukprj_humpty_diagnosis||0) + 
            (this.dataKajianPerawat.aukprj_humpty_gangguan_kognitif||0) + 
            (this.dataKajianPerawat.aukprj_humpty_faktor_lingkungan||0) + 
            (this.dataKajianPerawat.aukprj_humpty_respon_pembedahan||0) +
            (this.dataKajianPerawat.aukprj_humpty_respon_penggunaan_obat||0) 
        },

        morsheTotal(){
            return (this.dataKajianPerawat.aukprj_morshe_riwayat_jatuh||0) + 
            (this.dataKajianPerawat.aukprj_morshe_diagnosa||0) + 
            (this.dataKajianPerawat.aukprj_morshe_alat_bantu||0) + 
            (this.dataKajianPerawat.aukprj_morshe_inpus||0) + 
            (this.dataKajianPerawat.aukprj_morshe_cara_jalan||0) + 
            (this.dataKajianPerawat.aukprj_morshe_status_mental||0) 
        },

        getTotalSydney(){
            let total = 0
            
            if(this.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh == "Y" || this.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh2bulan == "Y"){
                total = total + 6
            }

            if(this.dataKajianPerawat.aukprj_status_mental_dellirium == "Y" || this.dataKajianPerawat.aukprj_status_mental_disorientasi == "Y" ||
            this.dataKajianPerawat.aukprj_status_mental_agitasi == "Y" ){
                total = total + 14
            }
            
            if(this.dataKajianPerawat.aukprj_penglihatan_kacamata == "Y" || this.dataKajianPerawat.aukprj_penglihatan_buram == "Y" ||
            this.dataKajianPerawat.aukprj_penglihatan_glaucoma == "Y" ){
                total = total + 1
            }

            if(this.dataKajianPerawat.aukprj_kebiasaan == "Y"){
                total = total + 2
            }

            if(this.dataKajianPerawat.aukprj_transfer + this.dataKajianPerawat.aukprj_mobilitas > 3){
                total = total + 7
            }
            return total  
        },
        showIntervensi(){
            if(this.dataKajianPerawat.aukprj_is_gangguan_jiwa == 'Y'){
                return this.edTotal >= 90
            }
            else{
                if(this.row.ap_usia <= 18){
                    return this.hdTotal >= 12
                }else if(this.row.ap_usia > 18 && this.row.ap_usia <= 60){
                    return this.morsheTotal >= 25
                }else if(this.row.ap_usia > 60){
                    return this.getTotalSydney >=6
                }else{
                    return false
                }
            }
        },
    },
    components: {
        Datepicker,VueTimepicker
    },
    methods: {   
        inputDataCA(date,jam,k){
            if(date && jam){
                this.data[k]['aucap_is_done'] = "Y"
            }else{
                this.data[k]['aucap_is_done'] = "N"
            }
        },
        changeAsesmenTindakan(k){
            this.data[k]['aucap_evaluasi_t1'] = null
            this.data[k]['aucap_evaluasi_t2'] = null
            this.data[k]['aucap_evaluasi_t3'] = null
            this.data[k]['aucap_evaluasi_t4'] = null
            this.data[k]['aucap_evaluasi_t5'] = null
            this.data[k]['aucap_evaluasi_text'] = null
        },
        changeDoneInput(e,k){
            if(e == "Y"){
                this.data[k]['aucap_done_date'] = this.now
            }else{
                this.data[k]['aucap_done_date'] = null
            }
        },
        back(){
            this.$router.back()
        },
        apiGet(params = {}, page = 1){
            
            this.loadingOverlay = true
            
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })

                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })
        },
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },
        removeData(v,k){
            let data = {
                id: v.aucap_id,
                type: 'remove-catatan-asuhan'
            }
            Gen.apiRest(
            "/do/" + 'UGDCatatanAsuhan', {
                data: data
            },
            "POST"
            ).then(() => {
                this.data.splice(k,1)
            })
        },
        addCatatan(){
            let data = {
                aucap_aur_id : this.$route.params.pageSlug,
                aucap_type: 'L',
                aucap_date: moment().format('YYYY-MM-DD'),
                aucap_jam: moment().format('HH:mm'),
                aucap_assesmen_tindakan: null,
                aucap_assesmen_tindakan_text: null,
                aucap_evaluasi_date: moment().format('YYYY-MM-DD'),
                aucap_evaluasi_jam: '',
                
                // aucap_evaluasi_date: moment().format('YYYY-MM-DD'),
                // aucap_evaluasi_jam: moment().add(10, 'minutes').format('HH:mm'),
                aucap_evaluasi_t1: null,
                aucap_evaluasi_t2: null,
                aucap_evaluasi_t3: null,
                aucap_evaluasi_t4: null,
                aucap_evaluasi_t5: null,
                aucap_evaluasi_text: null,
                aucap_ppa: 'Perawat',
                aucap_ppa_lainnya: this.user.fullName,
                aucap_assesmen_tindakan_sup_text: null,
                aucap_assesmen_tindakan_sup_text2: null,
                aucap_is_done: 'N',
                aucap_done_date: this.now,
                aucap_created_from: 'CA',
                type: 'add-catatan-asuhan'
            }
            console.log(data)

            Gen.apiRest(
                "/do/" + 'UGDCatatanAsuhan', {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.data.push(resp.row)
            })
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        doSubmit(){
            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                if(el[i].style.display !== 'none'){
                                    inv.push(el[i].id)
                                }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        text: this.row.ar_reg_code ? "Perubahan Data pada dokter, waktu periksa, dan poli akan mengubah nomor antrean" : "",
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            data.data = this.data
                            data.regID = this.rowReg.aur_id

                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'UGDCatatanAsuhan',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                }).then(result => {
                                    if (result.value) {
                                        if(this.user.levelId == 1){
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.rowReg.aur_ap_id }, query: {regId: this.rowReg.aur_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.rowReg.aur_ap_id }, query: {regId: this.rowReg.aur_id} }).catch(()=>{})
                                        }
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message

                                    if(err.title == "Terjadi gangguan koneksi pada server saat mengirim data"){
                                        this.$router.push({name : 'RoReservasi'}).catch(()=>{})
                                    }
                                }
                                this.doSetAlertForm(err)
                            })

                        }
                    })

                    // this.loadingOverlay = true

                }
            })
        },
        autoSaveCatatanAsuhan: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-catatan-asuhan',
                data: data
            }
            Gen.apiRest(
                "/do/" + 'UGDCatatanAsuhan', {
                data: dataPost
                },
                "POST"
            )
        }, 1000),
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            Gen.apiRest(
                "/do/"+'UGDCatatanAsuhan',
                {data:data}, 
                "POST"
            )
        },1000),
    },
    mounted() {
        setTimeout(()=>{
            this.initSticky()
        },1000)
    
        this.apiGet()
    },

    
    watch:{
        data: {
            handler(v) {
                this.autoSaveCatatanAsuhan(v)
            },
            deep: true
        },
        dataKajianPerawat: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}
</script>